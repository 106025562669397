// extracted by mini-css-extract-plugin
export var carouselContainer = "D_t7 d_w d_H d_bf d_Z";
export var carouselContainerCards = "D_t8 D_t7 d_w d_H d_bf d_Z";
export var carouselContainerSides = "D_t9 d_w d_H d_Z";
export var prevCarouselItem = "D_vb d_w d_H d_0 d_k";
export var prevCarouselItemL = "D_vc D_vb d_w d_H d_0 d_k";
export var moveInFromLeft = "D_vd";
export var prevCarouselItemR = "D_vf D_vb d_w d_H d_0 d_k";
export var moveInFromRight = "D_vg";
export var selectedCarouselItem = "D_vh d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "D_vj D_vh d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "D_vk D_vh d_w d_H d_Z d_bf";
export var nextCarouselItem = "D_vl d_w d_H d_0 d_k";
export var nextCarouselItemL = "D_vm D_vl d_w d_H d_0 d_k";
export var nextCarouselItemR = "D_vn D_vl d_w d_H d_0 d_k";
export var arrowContainer = "D_vp d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "D_vq D_vp d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "D_vr D_vq D_vp d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "D_vs D_vp d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "D_vt";
export var nextArrowContainerHidden = "D_vv D_vs D_vp d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "D_kG d_0";
export var prevArrow = "D_vw D_kG d_0";
export var nextArrow = "D_vx D_kG d_0";
export var carouselIndicatorContainer = "D_vy d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "D_vz d_w d_bz d_bF";
export var carouselText = "D_vB d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "D_vC D_vB d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "D_vD d_b7";
export var carouselIndicator = "D_vF D_vD d_b7";
export var carouselIndicatorSelected = "D_vG D_vD d_b7";
export var arrowsContainerTopRight = "D_vH d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "D_vJ d_0 d_bl d_bC";
export var arrowsContainerSides = "D_vK d_0 d_bl d_bC";
export var smallArrowsBase = "D_vL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "D_vM D_vL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "D_vN D_vM D_vL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "D_vP D_vL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "D_vQ D_vP D_vL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "D_qK";
export var iconSmall = "D_vR";
export var multipleWrapper = "D_vS d_bC d_bF d_bf";
export var multipleImage = "D_vT d_bC";
export var sidesPrevContainer = "D_vV D_vM D_vL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "D_vW D_vM D_vL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";