// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_rB d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_rC d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_rD d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_rF d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_rG d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_rH d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_rJ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rK z_rK";
export var heroExceptionNormal = "t_rL z_rL";
export var heroExceptionLarge = "t_rM z_rM";
export var Title1Small = "t_rN z_rN z_sd z_sf";
export var Title1Normal = "t_rP z_rP z_sd z_sg";
export var Title1Large = "t_rQ z_rQ z_sd z_sh";
export var BodySmall = "t_rR z_rR z_sd z_sx";
export var BodyNormal = "t_rS z_rS z_sd z_sy";
export var BodyLarge = "t_rT z_rT z_sd z_sz";