// extracted by mini-css-extract-plugin
export var alignLeft = "w_qR d_fp d_bG d_dv";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignRight = "w_qS d_fr d_bH d_dx";
export var testimonialsContainer = "w_r0 d_dW";
export var testimonialsContainerFull = "w_r1 d_dT";
export var testimonialsMainHeader = "w_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "w_jy d_jy";
export var testimonialsComponentSmall = "w_jx d_jx";
export var testimonialsComponentsWrapper = "w_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "w_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "w_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "w_r2 d_d7";
export var colEqualHeight = "w_r3 d_bz";
export var testimonialsColumnWrapper = "w_jz d_jz d_b3";
export var testimonialsImageWrapper = "w_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "w_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "w_jr d_jr d_Z d_bz";
export var design2TextWrapper = "w_r4 d_bC d_bP d_bJ";
export var design3 = "w_r5 d_cD d_H";
export var imageDesign2 = "w_r6";
export var SmallSmall = "w_r7 z_r7 z_sd z_sB";
export var SmallNormal = "w_r8 z_r8 z_sd z_sC";
export var SmallLarge = "w_r9 z_r9 z_sd z_sy";
export var exceptionWeight = "w_qY z_sG";